<template>
  <div class="log-activity-form">
    <div class="form-group" v-if="!hiddenFields.logDate">
      <label for="log-date">Date</label>
      <Calendar v-model="log.logDate" :minDate="minDate" :manualInput="false" :maxDate="maxDate" showTime hourFormat="12"
        :disabled="disabledFields.logDate" appendTo="body" />
    </div>

    <div class="form-group" v-if="pastActivity && !hiddenFields.practitioner">
      <label for="log-channel">Care Team Member</label>
      <Dropdown v-model="log.practitioner" :options="practitionerOptions" optionLabel="fullName"
        optionValue="id" scrollHeight="300px" appendTo="body" :disabled="disabledFields.practitioner">
      </Dropdown>
      <small v-if="!siteId" class="ml-2 text-red-400">No site found for this patient.</small>
    </div>

    <div class="form-group" v-if="!hiddenFields.purpose">
      <label for="log-purpose">Purpose</label>
      <Dropdown v-model="log.purpose" :options="purposesOptions" optionLabel="description" optionValue="name"
        scrollHeight="300px" appendTo="body" :disabled="disabledFields.purpose" @change="onPurposeChange"></Dropdown>
    </div>

    <div class="form-group" v-if="!hiddenFields.medium">
      <label for="log-channel">Channel</label>
      <div class="radio-group flex flex-wrap" :class="disabledFields.medium ? 'disabled' : ''">
        <div v-for="channel of channelOptions" :key="channel.key" class="field-radiobutton">
          <RadioButton :id="channel.key" name="channel" :value="channel.key" v-model="log.medium"
            :disabled="disabledFields.medium" />
          <label :for="channel.key">{{ channel.label }}</label>
        </div>
      </div>
    </div>

    <div class="form-group" v-if="pastActivity && !hiddenFields.direction && log.medium !== 'VERBAL_IN_PERSON'">
      <label for="log-channel">Direction</label>
      <div class="radio-group" :class="disabledFields.direction ? 'disabled' : ''">
        <div v-for="dir of directionOptions" :key="dir.key" class="field-radiobutton">
          <RadioButton :id="dir.key" name="dir" :value="dir.key" v-model="log.direction" :disabled="disabledFields.direction" />
          <label :for="dir.key" class="align-self-center">{{ dir.label }}</label>
        </div>
      </div>
    </div>

    <div class="form-group" v-if="!pastActivity && siteId && !hiddenFields.assignedPractitioner">
      <label for="log-purpose">Assign to</label>
      <Dropdown v-model="log.assignedPractitioner" :options="practitionerOptions" optionLabel="fullName"
        optionValue="id" scrollHeight="300px" appendTo="body" :disabled="disabledFields.assignedPractitioner">
      </Dropdown>
    </div>

    <div class="form-group" v-if="pastActivity && !hiddenFields.communicationResult">
      <label for="log-result">Result</label>
      <Dropdown v-model="log.communicationResult" :options="resultsOptions" optionLabel="label" optionValue="key"
        appendTo="body" :disabled="disabledFields.communicationResult" @change="onResultChange"></Dropdown>
    </div>

    <div v-if="pastActivity && !isSMS && !hiddenFields.subject" class="form-group">
      <label for="log-subject">Subject</label>
      <InputText v-model="log.subject" :disabled="disabledFields.subject"></InputText>
    </div>

    <div v-if="pastActivity && !isSMS && !hiddenFields.duration" class="form-group">
      <label for="log-duration">Duration (minutes)</label>
      <InputNumber :class="durationClass" v-model="log.duration" :maxFractionDigits="2" :disabled="disabledFields.duration" />
      <small v-if="showDurationError" id="duration-help" class="-mb-3 text-red-400">Duration must be greater than 0.</small>
    </div>

    <div class="form-group" v-if="pastActivity && isSMS && !hiddenFields.payload">
      <label for="log-payload">Message</label>
      <Textarea v-model="log.payload"></Textarea>
    </div>

    <LogNoteForm v-if="!prefill && !isEditMode" @textChange="onNoteChange" disableControls />
  </div>
</template>

<script>
import moment from 'moment';
import LogNoteForm from '../common/LogNoteForm.vue';
import { channelList, directionList, resultList } from './constants';
import { computed } from '@vue/composition-api';

export default {
  components: {
    LogNoteForm,
  },
  props: {
    logToEdit: {
      type: Object,
      default: null,
    },
    siteId: {
      type: String,
      required: false
    },
    pastActivity: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Object,
      default: () => ({})
    },
    hiddenFields: {
      type: Object,
      default: () => ({})
    },
    prefill: {
      type: Boolean,
      default: false,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { root, emit }) {
    const siteUsersBySiteId = computed(() => root.$store.getters['portal/siteUsersBySiteId']);
    const allSitesUsers = computed(() => root.$store.state.portal.allSitesUsers);
    const communicationPurposes = computed(() => root.$store.state.portal.communicationPurposes);

    const log = computed({
      get: () => props.logToEdit,
      set: (value) => emit('update:logToEdit', value)
    });

    const formType = computed(() => props.pastActivity ? 'LOGS' : 'TASKS');

    const filteredPurposes = computed(() => {
      return root.$store.getters['portal/filteredCommunicationPurposes'](formType.value);
    });

    const minDate = computed(() => props.pastActivity ? null : moment().subtract(1, 'day').toDate());
    const maxDate = computed(() => props.pastActivity ? moment().add(1, 'day').toDate() : null);
    const mediumKey = computed(() => log.value.medium);
    const isSMS = computed(() => mediumKey.value === 'WRITTEN_SMS');
    const isValidDuration = computed(() => log.value.duration > 0);
    const showDurationError = computed(() => log.value.duration !== null && log.value.duration !== undefined && !isValidDuration.value); 
    const channelOptions = computed(() => channelList.filter(channel => !channel.hidden));
    const excludeNonPatientInteractingPurposes = computed(() => log.value.medium !== 'NONE');
    const purposesOptions = computed(() => {
      const purposes = excludeNonPatientInteractingPurposes.value 
        ? filteredPurposes.value.filter(purpose => !purpose.hasNoPatientInteraction) 
        : filteredPurposes.value;
      return purposes;
    });
    const resultsOptions = computed(() => resultList[mediumKey.value]);
    const directionOptions = computed(() => directionList.filter(direction => !direction.hidden));
    const practitionerOptions = computed(() => {
      const siteUsers = !props.siteId ? allSitesUsers.value : siteUsersBySiteId.value(props.siteId);
      return siteUsers.filter(user => user.firstName || user.lastName).map(user => ({ ...user, fullName: [user?.firstName, user?.lastName].filter(Boolean).join(' ') }))
    })
    const durationClass = computed(() => ({
      'p-invalid': showDurationError.value,
      'disabled': props.disabledFields.duration,
    }))

    const onNoteChange = (note) => {
      log.value = {
        ...log.value,
        noteText: note
      };
    };

    const onPurposeChange = ({value}) => {
      const selectedPurpose = communicationPurposes.value.find(p => p.name === value);
      const updatedLog = {
        ...log.value
      }
      if (selectedPurpose?.defaultValues) {
        Object.entries(selectedPurpose.defaultValues).forEach(([key, definition]) => {
          if (log.value[key] === null || definition.overwriteCurrentValue) {
            updatedLog[key] = definition.value;
          }
        })
      }
      emit('update:logToEdit', updatedLog);
    }

    const onResultChange = ({value}) => {
      const selectedResult = resultList[log.value.medium]?.find(r => r.key === value);
      const updatedLog = {
        ...log.value
      }
      if (selectedResult?.defaultValues) {
        Object.entries(selectedResult.defaultValues).forEach(([key, definition]) => {
          if (log.value[key] === null || definition.overwriteCurrentValue) {
            updatedLog[key] = definition.value;
          }
        })
      }
      emit('update:logToEdit', updatedLog);
    }

    return {
      log,
      minDate, 
      maxDate,  
      isSMS, 
      showDurationError,
      channelOptions, 
      purposesOptions, 
      resultsOptions, 
      directionOptions, 
      practitionerOptions,
      durationClass,
      onNoteChange,
      onPurposeChange,
      onResultChange,
    };
  },
}
</script>
<style lang="scss" scoped>
.log-activity-form {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  .disabled {
    label {
      color: #495057;
      opacity: 0.5;
    }
    ::v-deep .p-inputnumber-input {
      background-color: #C6C6C6;
    }
  }
}

.radio-group {
  display: flex;
  margin-top: 8px;

  div {
    display: flex;
    align-items: center;
  }

  label {
    margin: 0 8px 0 4px;
    font-weight: bold;
    align-self: center;
  }
}
</style>