export const state = () => ({
  isRecording: false,
  isExpiring: false,
  error: '',
});

export const mutations = {
  SET_ERROR (state, error) {
    state.error = error;
  },
  SET_IS_RECORDING (state, isRecording) {
    state.isRecording = isRecording;
  },
  SET_IS_EXPIRING (state, isExpiring) {
    state.isExpiring = isExpiring;
  }
};

export const actions = {
  setIsRecording ({ commit }, isRecording) {
    commit('SET_IS_RECORDING', isRecording);
  },
  setIsExpiring ({ commit }, isExpiring) {
    commit('SET_IS_EXPIRING', isExpiring);
  },
  setError ({commit}, error) {
    commit('SET_ERROR', error);
  },
  clearError ({commit}) {
    commit('SET_ERROR', '');
  }
};

export const getters = {};
