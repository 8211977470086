import { ref, computed, getCurrentInstance } from "@vue/composition-api";

const defaultFetchParams = {
  sortField: 'lastName',
  sortOrder: 1,
  page: 0,
  size: 1000,
  filters: {
    firstName: {
      matchMode: 'contains',
      value: null,
    },
    lastName: {
      matchMode: 'contains',
      value: null,
    },
    id: {
      matchMode: 'equals',
      value: null,
    },
    insuranceMemberId: {
      matchMode: 'equals',
      value: null,
    },
    ehrId: {
      matchMode: 'equals',
      value: null,
    },
    phoneNumber: {
      matchMode: 'equals',
      value: null,
    },
    emailAddress: {
      matchMode: 'equals',
      value: null,
    },
    dateOfBirth: {
      matchMode: 'equals',
      value: null,
    },
  },
}

const defaultSearchFields = {
  firstName: {
    validFilters: ['equals','contains'],
    validationRules: [],
    errorMessage: null,
  },
  lastName: {
    validFilters: ['equals','contains'],
    validationRules: [],
    errorMessage: null,
  },
  id: {
    validFilters: ['equals'],
    validationRules: [{
      rule: (val) => /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(val),
      errorMessage: 'UUID does not match the expected format.'
    }, {
      rule: (val) => val?.length === 36,
      errorMessage: 'UUID should be exactly 36 characters, including hyphens.'
    }],
    errorMessage: null,
  },
  insuranceMemberId: {
    validFilters: ['equals'],
    validationRules: [],
    errorMessage: null,
  },
  ehrId: {
    validFilters: ['equals'],
    validationRules: [],
    errorMessage: null,
  },
  phoneNumber: {
    validFilters: ['equals'],
    validationRules: [{
      rule: (val) => val?.replace(/\D/g,'').length === 10,
      errorMessage: 'Phone number should have exactly 10 numbers. No country code needed.',
    }],
    errorMessage: null,
  },
  emailAddress: {
    validFilters: ['equals'],
    validationRules: [],
    errorMessage: null,
  },
  dateOfBirth: {
    validFilters: ['equals'],
    validationRules: [{
      rule: (val) => /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/.test(val),
      errorMessage: 'Date of birth must be in the format YYYY-MM-DD (i.e. 1970-01-31)',
    }],
    errorMessage: null,
  },
}

export function usePatientSearch (initialSearchFields = defaultSearchFields, initialFetchParams=defaultFetchParams) {
  const instance = getCurrentInstance();
  const apiv2 = instance?.proxy.$apiv2;
  
  // State
  const loading = ref(false);
  const showResults = ref(false);
  const matches = ref(null);
  const fetchParams = ref({...initialFetchParams});
  const searchFields = ref({...initialSearchFields});
  const error = ref(null);
  const totalRecords = ref(0);
  const latestSearchRef = ref(0);
  const lastUpdatedDateFromNow = ref(null);

  // Getters
  const getFetchParamValue = computed(() => (filterKey, filterParamKey) => {
    if (!fetchParams.value?.filters) {
      return undefined;
    }
    return fetchParams.value.filters[filterKey] ? fetchParams.value.filters[filterKey][filterParamKey] : null;
  });

  const flexibleMatchCompatibleFields = computed(() => {
    return Object.keys(searchFields.value).filter(field => searchFields.value[field].validFilters?.includes('contains'))
  });

  const isFlexibleMatchCompatibleField = computed(() => (fieldName) => {
    return flexibleMatchCompatibleFields.value.includes(fieldName);
  });

  const hasAnySearchFieldError = computed(() => {
    return Object.values(searchFields.value).some(({errorMessage}) => !!errorMessage);
  });

  const getErrorForSearchField = computed(() => (fieldKey) => {
    return searchFields.value[fieldKey].errorMessage;
  });

  // Actions
  async function searchProfiles (detailed = false) {
    loading.value = true;
    const searchParams = {
      ...fetchParams.value,
      filters: Object.entries(fetchParams.value.filters).reduce((acc, [key, val]) => {
        // Do not include values that are empty strings
        if (val?.value !== '') {
          return {
            ...acc,
            [key]: val,
          }
        }
        return acc;
      },{})
    }
    if (searchParams?.filters?.phoneNumber?.value) {
      searchParams.filters.phoneNumber = {
        ...searchParams.filters.phoneNumber,
        value: searchParams.filters.phoneNumber.value.replace(/\D/g,''),
      }
    }
    if (searchParams?.filters?.ehrId?.value) {
      searchParams.filters = {
        ...searchParams.filters,
        identifierType: {
          matchMode: 'equals',
          value: 'EhrId',
        },
        identifierValue: {
          matchMode: 'equals',
          value: searchParams.filters.ehrId.value,
        },
        ehrId: {
          matchMode: 'equals',
          value: null,
        },
      }
    }

    latestSearchRef.value += 1;
    const searchRef = latestSearchRef.value;

    try {
      // Detailed profiles return full profile in response; versus the default simple pii response.
      const {data, headers} = await (detailed ? apiv2.searchDetailedProfiles(searchParams) : apiv2.searchProfiles(searchParams));
      
      if (searchRef !== latestSearchRef.value) {return} // Do not update results if this is not the latest search.
      
      matches.value = data;
      totalRecords.value = parseInt(headers['x-total-count']);
      setLastUpdatedFromNow(new Date());
      if (matches.value) {
        showResults.value = true;
      }
    } catch (err) {
      error.value = err;
    } finally {
      loading.value = false;
    }
  }

  function setFetchParams(params) {
    fetchParams.value = params;
  }

  function updateFetchParams(filterKey, filterParamKey, value) {
    setFetchParams({
      ...fetchParams.value,
      filters: {
        ...fetchParams.value.filters,
        [filterKey]: {
          ...fetchParams.value.filters[filterKey],
          [filterParamKey]: value,
        }
      },
    });
    validateSearchFields();
  }

  function resetFetchParams() {
    setFetchParams({...initialFetchParams});
  }

  function setShowResults(val) {
    showResults.value = !!val;
  }

  function setLastUpdatedFromNow(val) {
    lastUpdatedDateFromNow.value = val;
  }

  function setLoading(val) {
    loading.value = val;
  }

  function setError(val) {
    error.value = val;
  }

  function setSearchFieldError(fieldKey, errorMessage) {
    searchFields.value[fieldKey].errorMessage = errorMessage;
  }

  function setSearchFieldValidationRules(fieldKey, rules) {
    searchFields.value[fieldKey].validationRules = rules;
  }

  function validateSearchFields() {
    Object.entries(searchFields.value).forEach(([field, {validationRules}]) => {
      setSearchFieldError(field, null);
      validationRules.forEach(({rule, errorMessage}) => {
        const inputValue = fetchParams?.value?.filters[field].value;
        if (inputValue && !rule(inputValue)) {
          setSearchFieldError(field, errorMessage);
        };
      });
    });
  }

  return {
    // State
    loading,
    showResults,
    matches,
    fetchParams,
    error,
    totalRecords,
    lastUpdatedDateFromNow,

    // Getters
    getFetchParamValue,
    flexibleMatchCompatibleFields,
    isFlexibleMatchCompatibleField,
    hasAnySearchFieldError,
    getErrorForSearchField,

    // Actions
    searchProfiles,
    setFetchParams,
    updateFetchParams,
    resetFetchParams,
    setShowResults,
    setLoading,
    setError,
    setSearchFieldError,
    setSearchFieldValidationRules,
    validateSearchFields,
  };
}
