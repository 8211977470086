<template>
  <SidebarContent :id="id" :payload="payload">
    <template v-slot:sidebar-header>
      <div class="flex align-items-center font-medium text-2xl">
        <span>Session Recording</span>
      </div>
    </template>
    <template v-slot:sidebar-content>
      <div class="flex flex-column flex-grow-0 time-tracker">
        <Button id="time-tracker-patient-search-button" class="mt-9 mb-3 mx-3" :label="patientSearchLabel" 
          @click="patientSearch_clickHandler" aria:haspopup="true" aria-controls="timeTrackerSearchOverlay" />
        <OverlayPanel ref="timeTrackerSearchOverlay" appendTo="body" id="time-tracker-search-overlay" :autoZIndex="true" :dismissable="false" showCloseIcon>
          <PatientSearch @toggleOverlay="toggleSearchOverlay" @profileSelected="profile_clickHandler" applySiteAccess />
        </OverlayPanel>
        <div class="my-3 font-medium text-lg flex justify-content-center">
          <span v-if="profileFullName" class="text-center text-xl">{{profileFullName}} {{ displayDuration }}</span>
        </div>
        <div class="flex flex-row justify-content-center">
          <Button class="mx-2" icon="pi pi-play" @click="startRecording" :disabled="startTime || !profile || creatingLog" />
          <Button class="mx-2" icon="pi pi-stop" @click="stopRecording" :disabled="!startTime || creatingLog" />
        </div>
        <div class="my-6 flex justify-content-center" v-if="isExpiring">
          <Button class="my-3 p-button-danger" label="Extend session" @click="extendSession" />
        </div>
        <div class="my-3">
          <SpeechRecognition :recording="!!startTime" @update="speechRecognition_updateHandler" />
        </div>
      </div>
    </template>
    <template v-slot:sidebar-footer>
    </template>
  </SidebarContent>
</template>

<script>
import PatientSearch from "@/components/common/PatientSearch";
import SidebarContent from "@/components/layout/sidebar/SidebarContent";
import SpeechRecognition from "@/components/speechRecognition/SpeechRecognition.vue";
import { SIDEBAR_TAGS } from "../SidebarConstants";
import moment from 'moment';
import { ref, computed, onMounted, onBeforeUnmount, watch } from '@vue/composition-api';
import {setupScrollDetection} from "@/helpers";

const RENEWAL_PERIOD = 20;  // Session must be renewed every RENEWAL_PERIOD minutes

const defaultMaLog = {
  communicationResult: "PATIENT_UPDATED",
  direction: "NONE",
  source: "PORTAL_SPEECH_RECOGNITION",
  medium: "VERBAL_IN_PERSON",
  purpose: "MA_ACTIVITY",
  subject: "",
}

const defaultInClinicAppointmentLog = {
  communicationResult: "APPOINTMENT_COMPLETE",
  direction: "NONE",
  source: "PORTAL_SPEECH_RECOGNITION",
  medium: "VERBAL_IN_PERSON",
  purpose: "IN_CLINIC_APPOINTMENT",
  subject: "",
}

export default {
  components: {
    PatientSearch,
    SidebarContent,
    SpeechRecognition,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    payload: {
      type: Object,
      required: false,
    },
  },
  setup (props, { root, refs }) {
    const profile = ref(null);
    const startTime = ref(null);
    const currentTime = ref(null);
    const timerInterval = ref(null);
    const expiration = ref(null);
    const noteText = ref('');
    const creatingLog = ref(false);
    const logQueue = ref([]);
    const currentLog = ref(null);

    const profilePayload = computed(() => props.payload?.profile || null);

    const profileFullName = computed(() => {
      return profile.value ? `${profile.value.firstName || profile.value.first_name || ''} ${profile.value.lastName || profile.value.last_name || ''}` : undefined;
    })

    const duration = computed(() => {
      if (!startTime.value || !currentTime.value) {
        return null;
      }
      return moment.duration(currentTime.value.diff(startTime.value));
    })

    const displayDuration = computed(() => {
      return duration.value ? moment.utc(duration.value.as('milliseconds')).format('HH:mm:ss')  : ''
    });

    const timeUntilExpiration = computed(() => {
      if (!currentTime.value || !expiration.value) {
        return Number.MAX_VALUE;
      }
      return expiration.value.diff(currentTime.value);
    })

    const isExpiring = computed(() => root.$store.state.timeTracker.isExpiring);
    const globalSiteRoles = computed(() => root.$store.getters['portal/globalSiteRoles']);
    const isMedicalAssistant = computed(() => globalSiteRoles.value?.some(role => role.code === 'medical_assistant'));
    const userId = computed(() => root.$store.getters['portal/userId']);

    const patientSearchLabel = computed(() => {
      return profile.value ? "Start New Patient" : "Select Patient";
    })

    const addSidebar = (payload) => root.$store.dispatch('sidebar/addSidebar', payload);
    const setIsRecording = (payload) => root.$store.dispatch('timeTracker/setIsRecording', payload);
    const setIsExpiring = (payload) => root.$store.dispatch('timeTracker/setIsExpiring', payload);

    const patientSearch_clickHandler = (e) => {
      toggleSearchOverlay(e);
    }

    const profile_clickHandler = (selectedProfile) => {
      setProfile(selectedProfile);
    }

    const setProfile = (newProfile) => {
      if (startTime.value) {
        stopRecording();
      }
      profile.value = newProfile;
      startRecording();
      toggleSearchOverlay(false);
    }

    const startRecording = () => {
      window.addEventListener('beforeunload', handleBeforeUnload);
      startTime.value = moment();
      timerInterval.value = setInterval(() => {
        currentTime.value = moment();
      }, 1000) 
      setIsRecording(true);
      expiration.value = moment(startTime.value).add(RENEWAL_PERIOD, 'minutes');
      submitLog(profile.value?.id);
    }

    const extendSession = () => {
      expiration.value.add(RENEWAL_PERIOD, 'minutes');
      setIsExpiring(false);
    }

    const stopRecording = () => {
      startTime.value && addCurrentLogToQueue();
      startTime.value = null;
      clearInterval(timerInterval.value);
      timerInterval.value = null;
      currentTime.value = null;
      noteText.value = null;
      setIsRecording(false);
      isExpiring.value && setIsExpiring(false);
      currentLog.value = null;
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    const addCurrentLogToQueue = () => {
      logQueue.value.push({
        id: currentLog.value?.id,
        profileId: profile.value?.id,
        startTime: startTime.value,
        endTime: currentTime.value,
        noteText: noteText.value,
      })
    }

    const submitLog = async (profileId) => {
      let newLog = null;
      creatingLog.value = true;
      try {
        const defaultLog = isMedicalAssistant.value ? defaultMaLog : defaultInClinicAppointmentLog;
        const logData = {
          ...defaultLog,
          communicationResult: 'IN_PROGRESS',   
          notes: [],
          sent: new Date().toISOString(),
          practitioner: userId.value,
        }
        newLog = await root.$apiv2.addCommunicationLog(profileId, logData);
        currentLog.value = newLog;
        root.$toast.add({ severity: 'success', summary: 'Success', detail: 'Log Created Successfully', life: 3000 })
      } catch (e) {
        root.$toast.add({
            severity: 'error',
            summary: 'Something went wrong',
            detail: `Log was not created. ${e.message}`,
            life: 3000,
        })
      }
      creatingLog.value = false;
    }

    const updateLog = async (logPayload) => {
      try {
        const {noteText: newNote} = logPayload;
        const updatedLog = {
          id: logPayload.id,
          encounter: {
            durationInSeconds: Math.round((logPayload.endTime - logPayload.startTime)/1000)
          },
          communicationResult: (isMedicalAssistant.value ? defaultMaLog.communicationResult : defaultInClinicAppointmentLog.communicationResult),
        }
        await root.$apiv2.updateCommunicationLog(updatedLog);
        newNote && await root.$apiv2.addCommunicationLogNote(updatedLog.id, {text: newNote})
        root.$toast.add({ severity: 'success', summary: 'Success', detail: 'Recording Saved Successfully', life: 3000 })
      } catch (e) {
        root.$toast.add({
            severity: 'error',
            summary: 'Something went wrong',
            detail: `Error saving recording. ${e.message}`,
            life: 3000,
        })
      }
    }

    const toggleSearchOverlay = (event) => {
      refs.timeTrackerSearchOverlay.toggle(event);
      event && setTimeout(() => {
        refs.timeTrackerSearchOverlay && fixOverlay('time-tracker-search-overlay');
      },500);
    }

    const fixOverlay = (overlayId) => {
      const overlay = document.getElementById(overlayId);
      const target = document.getElementById('time-tracker-patient-search-button');
      const targetRect = target.getBoundingClientRect();
      if (overlay && targetRect) {
        overlay.style.position = 'fixed';
        overlay.style.top = targetRect.bottom + 'px';
      } else {
        console.error('Could not find HTML element to position overlay ', overlayId);
      }
    }

    // Warn the user if they are navigating from the portal while a recording is in progress.
    const handleBeforeUnload = (e) => {
      const message = "Are you sure you want to leave? You have a recording in progress.";
      e.returnValue = message;
      return message;
    }

    const speechRecognition_updateHandler = (text) => {
      noteText.value = text;
    }

    onMounted(() => {
      setupScrollDetection('.time-tracker','show-scrollbar');
    });

    onBeforeUnmount(() => {
      stopRecording();
    });

    watch(() => logQueue.value, (newVal) => {
      if (newVal.length) {
        const log = newVal.pop();
        updateLog(log);
        logQueue.value = newVal;
      }
    })

    // Watches expiration limit, and handles expiration and 1 minute warning.
    watch(() => timeUntilExpiration.value, (newVal) => {
      if (expiration.value && newVal < 0) {
        stopRecording();
      } else if (expiration.value && newVal < 60000) {
        if (!isExpiring.value) {
          addSidebar({tag: SIDEBAR_TAGS.TIME_TRACKER})
          setIsExpiring(true);
        }
      }
    })

    // If a new profile is passed into the sidebar payload, set the profile.
    watch(() => profilePayload.value, (newVal) => {
      if (newVal && newVal?.id !== profile.value?.id) {
        setProfile(newVal);
      }
    }, { immediate: true })

    // When a new log is created, open the log in a COMMUNICATION_LOG sidebar.
    watch(() => currentLog.value, (newVal) => {
      newVal && addSidebar({
        tag: SIDEBAR_TAGS.COMMUNICATION_LOG,
        payload: {
          logId: newVal.id,
        }
      })
    })

    return {
      creatingLog,
      isExpiring,
      patientSearchLabel,
      profile,
      profileFullName,
      displayDuration,
      startTime,
      extendSession,
      fixOverlay,
      startRecording,
      stopRecording,
      patientSearch_clickHandler,
      profile_clickHandler,
      speechRecognition_updateHandler,
      toggleSearchOverlay,
    }
  },
};
</script>

<style lang="scss" scoped>
.sidebar-main {
  padding: 20px 24px 24px;
}

.time-tracker {
  overflow-y: auto;
}

.entry-header {
  color: #135EAE;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.show-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: transparent; 
  border-radius: 4px;
}

.show-scrollbar::-webkit-scrollbar-thumb {
  background: #609af8;
}
</style>
