<template>
  <div class="patient-search-overlay flex flex-column align-items-center">
    <InlineMessage v-if="error" class="flex flex-grow-0 justify-content-start align-items-center my-2"
      severity="error">{{ error }}
    </InlineMessage>
    <ProgressSpinner v-if="loading" class="mt-4 mb-3" style="width: 50px; height: 50px" />
    <div v-else-if="showResults" class="patient-search-overlay flex">
      <div class="flex flex-column overflow-y-hidden">
        <span class="mb-3 text-2xl">Results {{resultsCount}}</span>
        <div v-if="!matches.length" class="flex flex-column">
          <span class="mb-1 text-xl">Sorry there are no matching results</span>
          <span class="mb-1 text-base">Please try refiltering with new search criteria</span>
        </div>
        <div class="flex flex-column overflow-y-auto profile-results">
          <div :key="match.id" v-for="match in matches" @click="profile_clickHandler(match)" :class="accessibleSiteSelfClass(match)"
            class="profile-result p-3 my-2 ml-1 mr-3 flex flex-row justify-content-between border-round-2xl shadow-1">
            <div class="flex flex-column">
              <span class="text-sm">{{displaySiteNamesForMatch(match)}}</span>
              <span class="font-bold text-2xl">{{match.dateOfBirth || 'Unknown Date of Birth'}}</span>
              <span class="font-semibold text-xl text-primary">{{displayPatientName(match)}}</span>
              <span class="font-base text-lg">EHR ID: {{getEhrId(match) || '--'}}</span>
            </div>
            <div v-if="showGrantAccessButton(match)" class="flex flex-row flex-grow-0 align-items-center">
              <Button class="p-button-text p-button mr-2" icon="pi pi-external-link"
              v-tooltip="'Grant Access'" @click="openSiteUserCreationInAdmin(getAnySiteForProfile(match))" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="w-full">
      <div class="flex flex-column">
        <span class="mb-3 text-2xl">Search Patients</span>
        <span class="mb-1 text-lg">Use any or all of the fields below to search:</span>
        <span class="mb-1 text-sm">Any text fields left empty will not be applied to the search.</span>
        <Divider />
        <span class="-mb-2 text-sm align-self-end">Apply Flexible Match:</span>
      </div>
      <PatientSearchInput fieldKey="lastName" displayName="Last Name" :isFlexibleMatchCapable="isFlexibleMatchCompatibleField('lastName')"
        :inputValue="getFetchParamValue('lastName','value')" @inputChange="updateFetchParams"
        :checkboxValue="getCheckboxValue('lastName')"  @checkboxSelected="updateFetchParams" @enterPressed="search_clickHandler" />
      <PatientSearchInput fieldKey="firstName" displayName="First Name" :isFlexibleMatchCapable="isFlexibleMatchCompatibleField('firstName')"
        :inputValue="getFetchParamValue('firstName','value')" @inputChange="updateFetchParams"
        :checkboxValue="getCheckboxValue('firstName')"  @checkboxSelected="updateFetchParams" @enterPressed="search_clickHandler" />
      <PatientSearchInput fieldKey="ehrId" displayName="EHR ID" :isFlexibleMatchCapable="isFlexibleMatchCompatibleField('ehrId')"
        :inputValue="getFetchParamValue('ehrId','value')" @inputChange="updateFetchParams"
        :checkboxValue="getCheckboxValue('ehrId')"  @checkboxSelected="updateFetchParams" @enterPressed="search_clickHandler" />
      <PatientSearchInput fieldKey="phoneNumber" displayName="Phone Number" :isFlexibleMatchCapable="isFlexibleMatchCompatibleField('phoneNumber')"
        :inputValue="getFetchParamValue('phoneNumber','value')" @inputChange="updateFetchParams"
        :checkboxValue="getCheckboxValue('phoneNumber')"  @checkboxSelected="updateFetchParams" @enterPressed="search_clickHandler" :error="getErrorForSearchField('phoneNumber')" />
      <PatientSearchInput fieldKey="emailAddress" displayName="Email" :isFlexibleMatchCapable="isFlexibleMatchCompatibleField('emailAddress')"
        :inputValue="getFetchParamValue('emailAddress','value')" @inputChange="updateFetchParams"
        :checkboxValue="getCheckboxValue('emailAddress')"  @checkboxSelected="updateFetchParams" @enterPressed="search_clickHandler" />
      <PatientSearchInput fieldKey="dateOfBirth" displayName="Date of Birth (YYYY-MM-DD)" :isFlexibleMatchCapable="isFlexibleMatchCompatibleField('dateOfBirth')"
        :inputValue="getFetchParamValue('dateOfBirth','value')" @inputChange="updateFetchParams"
        :checkboxValue="getCheckboxValue('dateOfBirth')"  @checkboxSelected="updateFetchParams" @enterPressed="search_clickHandler" :error="getErrorForSearchField('dateOfBirth')"/>
      <PatientSearchInput fieldKey="id" displayName="UUID" :isFlexibleMatchCapable="isFlexibleMatchCompatibleField('id')"
        :inputValue="getFetchParamValue('id','value')" @inputChange="updateFetchParams"
        :checkboxValue="getCheckboxValue('id')"  @checkboxSelected="updateFetchParams" @enterPressed="search_clickHandler" :error="getErrorForSearchField('id')"/>
      <PatientSearchInput fieldKey="insuranceMemberId" displayName="Member ID" :isFlexibleMatchCapable="isFlexibleMatchCompatibleField('insuranceMemberId')"
        :inputValue="getFetchParamValue('insuranceMemberId','value')" @inputChange="updateFetchParams"
        :checkboxValue="getCheckboxValue('insuranceMemberId')"  @checkboxSelected="updateFetchParams" @enterPressed="search_clickHandler" />
    </div>
    <div class="flex flex-row justify-content-between w-full">
      <Button v-if="!loading && showResults" class="mt-3 ml-2 p-button-text" type="button" icon="pi pi-chevron-left" label="Refilter" @click="navigateBack_clickHandler" />
      <Button v-if="!error" class="mt-3 ml-2 p-button-text" type="button" icon="pi pi-refresh" label="Clear" @click="clearFilters_clickHandler" />
      <Button v-if="!loading && !showResults" class="mt-3 ml-2" type="button" icon="pi pi-search" label="Search" :disabled="!canSearch" @click="search_clickHandler" />
    </div>
  </div>
</template>

<script>
import PatientSearchInput from './PatientSearchInput';
import { usePatientSearch } from '@/composables/patientSearch';
import { computed, onMounted } from '@vue/composition-api';

export default {
    components: {
      PatientSearchInput,
    },
    props: {
      applySiteAccess: {
        type: Boolean,
        default: false,
      }
    },
    setup(props, { root, emit }) {
      const applySiteAccess = computed(() => props.applySiteAccess);
      
      const {
        loading, showResults, matches, fetchParams, error, totalRecords, // State
        getFetchParamValue, isFlexibleMatchCompatibleField, hasAnySearchFieldError, getErrorForSearchField, // Getters
        searchProfiles, updateFetchParams, resetFetchParams, setShowResults, setLoading, 
        setError, setSearchFieldValidationRules, validateSearchFields, // Actions
      } = usePatientSearch();

      const userId = computed(() => root.$store.getters['portal/userId']);
      const isStaff = computed(() => root.$store.getters['portal/isStaff']);
      const userHasAccessToAtLeastOneSiteForProfile = computed(() => root.$store.getters['portal/userHasAccessToAtLeastOneSiteForProfile']);
      const gatewayNewSiteUserUrl = computed(() => root.$store.getters['portal/gatewayNewSiteUserUrl']);

      const canSearch = computed(() => {
        return fetchParams.value?.filters && Object.entries(fetchParams.value.filters).some(([key, val]) => {
          return !!fetchParams.value.filters[key].value;
        }) && !hasAnySearchFieldError.value;
      });

      const resultsCount = computed(() => {
        return parseInt(totalRecords.value) ? `(${totalRecords.value})` : '';
      })

      const accessibleSiteSelfClass = (profile) => {
        return applySiteAccess.value && !userHasAccessToAtLeastOneSiteForProfile.value(profile) ? 'inaccessible' : '';
      }

      const displaySiteNamesForMatch = (profile) => {
        return profile.siteProfiles?.reduce((acc,siteProfile) => {
          if (siteProfile?.site?.full_name) {
            acc.push(siteProfile.site.full_name);
          }
          return acc;
        },[]).join(', ');
      }

      const getAnySiteForProfile = (profile) => {
        return profile.siteProfiles.length && profile.siteProfiles[0] && profile.siteProfiles[0].site;
      }

      const displayPatientName = (profile) => {
        return profile.firstName || profile.lastName ? `${profile.firstName || ''} ${profile.lastName || ''}` : `[Unknown Patient]`;
      }

      const getEhrId = (profile) => {
        return profile && profile.identifiers?.EhrId;
      }

      const getCheckboxValue = (filterKey) => {
        return getFetchParamValue.value(filterKey, 'matchMode') === 'equals' ? false : true;
      }

      const showGrantAccessButton = (profile) => {
        return applySiteAccess.value && !userHasAccessToAtLeastOneSiteForProfile.value(profile) && isStaff.value && getAnySiteForProfile(profile);
      }

      const search_clickHandler = () => {
        searchProfiles();
      }

      const clearFilters_clickHandler = () => {
        resetFetchParams();
        setShowResults(false);
        setLoading(false);
        setError(null);
      }

      const navigateBack_clickHandler = () => {
        setShowResults(false);
        setError(null);
        setTimeout(() => {
          addPhoneNumberEventListener();
          addDateOfBirthEventListener();
        },500);
      }

      const profile_clickHandler = (profile) => {
        emit('profileSelected', profile)
      }

      const openSiteUserCreationInAdmin = (site) => {
        if (userId.value && site?.id) {
          const url = gatewayNewSiteUserUrl.value(site.id);
          window.open(url, '_blank');
        } else if (!userId.value) {
          setError('Error: User not found');
        } else {
          setError('Error: No site id');
        }
      }

      const addDateOfBirthEventListener = () => {
        const dateOfBirthInput = document.getElementById('patient-search-dateOfBirth');
        const validDateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
        // If the field is still in focus, don't show error for less than 10 characters.
        dateOfBirthInput.addEventListener('focus', () => {
          setSearchFieldValidationRules('dateOfBirth', [{
            rule: (val) => val.length < 10 || validDateRegex.test(val),
            errorMessage: 'Date of birth must be in the format YYYY-MM-DD (i.e. 1970-01-31)',
          }]);
        });
        dateOfBirthInput.addEventListener('blur', () => {
          setSearchFieldValidationRules('dateOfBirth', [{
            rule: (val) => validDateRegex.test(val),
            errorMessage: 'Date of birth must be in the format YYYY-MM-DD (i.e. 1970-01-31)',
          }]);
          validateSearchFields();
        });
      }

      const addPhoneNumberEventListener = () => {
        const phoneNumberInput = document.getElementById('patient-search-phoneNumber');
        // If the field is still in focus, don't show error for less than 10 digits.
        phoneNumberInput.addEventListener('focus', () => {
          setSearchFieldValidationRules('phoneNumber', [{
            rule: (val) => val.replace(/\D/g,'').length <= 10,
            errorMessage: 'Phone number should have exactly 10 numbers. No country code needed.',
          }]);
        });
        phoneNumberInput.addEventListener('blur', () => {
          setSearchFieldValidationRules('phoneNumber', [{
            rule: (val) => val.replace(/\D/g,'').length === 10,
            errorMessage: 'Phone number should have exactly 10 numbers. No country code needed.',
          }]);
          validateSearchFields();
        });
      }

      onMounted(() => {
        addPhoneNumberEventListener();
        addDateOfBirthEventListener();
      })

      return {
        loading,
        showResults,
        matches,
        error,
        getFetchParamValue,
        isFlexibleMatchCompatibleField,
        getErrorForSearchField,
        updateFetchParams,
        canSearch,
        resultsCount,
        accessibleSiteSelfClass,
        displaySiteNamesForMatch,
        getAnySiteForProfile,
        displayPatientName,
        getEhrId,
        getCheckboxValue,
        showGrantAccessButton,
        search_clickHandler,
        clearFilters_clickHandler,
        navigateBack_clickHandler,
        profile_clickHandler,
        openSiteUserCreationInAdmin,
      }
    },
}
</script>

<style lang="scss" scoped>
.patient-search-overlay {
  min-width: 382px;
  max-width: 382px;
  width: 100%;
  max-height: 75vh;
  overflow-y: auto;
  ::v-deep .p-divider {
    border-top: 1px solid #DEE2E6;
  }
  .profile-result {
    border: 1px solid var(--surface-300);
    &:hover {
      background-color: #E9F4FF;
      border-color: #0073E6;
      transform: scale(1.01);
      cursor: pointer;
    }
    &.inaccessible:hover {
      background-color: transparent;
      border-color: #FF5050;
      cursor: default;
    }
  }
}
</style>