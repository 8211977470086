export const state = () => ({
  loading: false,
  error: false,
  logs: [],
  filter: { startDate: null, endDate: null },
  totalRecords: 0,
});

export const mutations = {
  SET_LOADING (state, loading) {
    state.loading = loading;
  },
  SET_ERROR (state, error) {
    state.error = error;
  },
  SET_FILTER (state, filter) {
    state.filter = filter;
  },
  SET_LOGS (state, logs) {
    state.logs = logs;
  },
  UPDATE_LOG(state, {logIndex, updatedLog}) {
    state.logs.splice(logIndex, 1, updatedLog);
  },
  ADD_LOG (state, log) {
    state.logs.push(log);
  },
  ADD_LOG_NOTE (state, newNote) {
    const logIndex = state.logs.findIndex(log => log.id === newNote.communication.id);
    if (logIndex !== -1) {
      state.logs[logIndex].notes.push(newNote);
    }
  },
  UPDATE_LOG_NOTE (state, updatedNote) {
    const logIndex = state.logs.findIndex(log => log.id === updatedNote.communication.id);
    if (logIndex !== -1) {
      const noteIndex = state.logs[logIndex].notes.findIndex(note => note.id === updatedNote.id);
      if (noteIndex !== -1) {
        state.logs[logIndex].notes.splice(noteIndex, 1, updatedNote);
      }
    }
  },
  DELETE_LOG_NOTE (state, noteId) {
    const logIndex = state.logs.findIndex(log => log.notes.some(note => note.id === noteId));
    if (logIndex !== -1) {
      state.logs[logIndex].notes = state.logs[logIndex].notes.filter(note => note.id !== noteId);
    }
  },
  SET_TOTAL_RECORDS (state, totalRecords) {
    state.totalRecords = totalRecords;
  },
};

export const actions = {
  setFilter ({ commit }, filter) {
    commit('SET_FILTER', filter);
  },
  async fetchLogs ({ commit }, params) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const response = await this.$apiv2.getCommunicationLogs(params);
      
      let logs = [];
      if (response.content) {
        logs = response.content;
      } else if (Array.isArray(response)) {
        logs = response;
      } else if (response.data && Array.isArray(response.data)) {
        logs = response.data;
      }
      
      commit('SET_LOGS', logs);
      
      if (response.headers && response.headers['x-total-count']) {
        commit('SET_TOTAL_RECORDS', parseInt(response.headers['x-total-count']));
      } else if (response.totalElements !== undefined) {
        commit('SET_TOTAL_RECORDS', response.totalElements);
      }
      
      commit('SET_LOADING', false);
      return response;
    } catch(err) {
      commit('SET_ERROR', err);
      commit('SET_LOADING', false);
    }
  },
  async fetchLog ({ commit, state }, id) {
    try {
      const fetchedLog = await this.$apiv2.getCommunicationLogById(id);
      const logIndex = state.logs.findIndex(log => log.id === fetchedLog.id);
      if (logIndex !== -1) {
        commit('UPDATE_LOG', {logIndex, updatedLog: fetchedLog});
      }
      return fetchedLog;
    } catch(err) {
      console.error(err);
      return null;
    }
  },
  async fetchLogByCallId ({ commit, state }, callId) {
    try {
      const fetchedLog = await this.$apiv2.getCommunicationLogByCallId(callId);
      const logIndex = state.logs.findIndex(log => log.id === fetchedLog.id);
      if (logIndex !== -1) {
        commit('UPDATE_LOG', {logIndex, updatedLog: fetchedLog});
      }
      return fetchedLog;
    } catch(err) {
      console.error(err);
      return null;
    }
  },
  async updateLog ({ commit, state }, {logPayload}) {
    try {
      const updatedLog = await this.$apiv2.updateCommunicationLog(logPayload);
      const logIndex = state.logs.findIndex(log => log.id === updatedLog.id);
      if (logIndex !== -1) {
        commit('UPDATE_LOG', {logIndex, updatedLog});
      }
      return updatedLog;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async createLog ({ commit }, { profileId, logData }) {
    const newLog = await this.$apiv2.addCommunicationLog(profileId, logData);
    commit('ADD_LOG', newLog);
    return newLog;
  },
  async addNoteToLog ({ commit }, { logId, notePayload }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const newNote = await this.$apiv2.addCommunicationLogNote(logId, notePayload);
      commit('ADD_LOG_NOTE', newNote);
      commit('SET_LOADING', false);
      return newNote;
    } catch (err) {
      commit('SET_ERROR', err);
      commit('SET_LOADING', false);
    }
  },
  async updateNoteOnLog ({ commit }, { noteId, notePayload }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const updatedNote = await this.$apiv2.updateCommunicationLogNote(noteId, notePayload);
      commit('UPDATE_LOG_NOTE', updatedNote);
      commit('SET_LOADING', false);
      return updatedNote;
    } catch (err) {
      commit('SET_ERROR', err);
      commit('SET_LOADING', false);
    }
  },
  async deleteNoteOnLog ({ commit }, { noteId }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      await this.$apiv2.deleteCommunicationLogNote(noteId);
      commit('DELETE_LOG_NOTE', noteId);
      commit('SET_LOADING', false);
      return {
        deleted: noteId,
      }
    } catch (err) {
      commit('SET_ERROR', err);
      commit('SET_LOADING', false);
    }
  }
};

export const getters = {
  logById: (state) => (id) => state.logs.find(log => log.id === id),
  isInProgress: () => (log) => {
    return log.communicationResult === 'IN_PROGRESS';
  },
};

