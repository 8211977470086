import { SIDEBAR_TAGS, PERSISTENT_SIDEBARS, SIDEBAR_BROADCAST_EVENT_TYPES } from "@/components/layout/sidebar/SidebarConstants";

export const state = () => ({
  activeSidebars: [],
  expanded: false,
  maximizedId: null,
  nextId: 1,
  broadcastChannel: null,
});

export const mutations = {
  ADD_SIDEBAR (state, { tag, payload }) {
    const existing = state.activeSidebars.find(
        sidebar => sidebar.tag === tag && JSON.stringify(sidebar.payload) === JSON.stringify(payload)
    )
    if (!existing) {
      state.activeSidebars.push({
        id: state.nextId,
        tag,
        payload,
      });
      state.nextId += 1;
    }
  },
  REMOVE_SIDEBAR (state, id) {
    state.activeSidebars = state.activeSidebars.filter(s => s.id !== id);
  },
  UPDATE_PAYLOAD (state, { sidebarId, payload }) {
    const existingSidebar = state.activeSidebars.find(s => s.id === sidebarId);
    if (existingSidebar) {
      existingSidebar.payload = payload;
    }
  },
  SET_EXPANDED (state, expanded) {
    state.expanded = expanded;
  },
  SET_MAXIMIZED_ID (state, sidebarId) {
    state.maximizedId = sidebarId;
  },
  SET_BROADCAST_CHANNEL (state, channel) {
    state.broadcastChannel = channel;
  }
};

export const actions = {
  expandSidebar ({ commit, state }) {
    if (!state.expanded) {
      commit('SET_EXPANDED', true);
    }
  },
  collapseSidebar ({ commit, state }) {
    if (state.expanded) {
      commit('SET_EXPANDED', false);
    }
  },
  addSidebar ({ commit, state }, payload) {
    if (!payload || !payload.tag) {
      console.error('Tag required to add a sidebar.');
    }

    if (!Object.values(SIDEBAR_TAGS).includes(payload.tag)) {
      console.warn('Provided sidebar tag is not recognized: ', payload.tag);
    }

    const sidebar = state.activeSidebars.find(s => s.tag === payload.tag);

    if (sidebar && PERSISTENT_SIDEBARS.includes(payload.tag)) {
      // Once a persistent sidebar is mounted, addSidebar will just update its payload.
      const updatedPayload = {
        ...sidebar.payload,
        hidden: false,
        ...payload.payload,
      }
      commit('UPDATE_PAYLOAD', { sidebarId: sidebar.id, payload: updatedPayload });
    } else {
      commit('ADD_SIDEBAR', payload);
    }
  },
  removeSidebar ({ commit, state, dispatch }, sidebarId) {
    if (!sidebarId) {
      console.error('ID required to remove a sidebar');
      return;
    }

    const sidebar = state.activeSidebars.find(s => s.id === sidebarId);

    if (!sidebar) {
      console.error('No sidebar found with id ', sidebarId);
      return;
    }

    if (PERSISTENT_SIDEBARS.includes(sidebar.tag)) {
      // Instead of unmounting a persistent sidebar, its payload is updated.
      const payload = {
        ...sidebar.payload,
        hidden: true,
      }
      commit('UPDATE_PAYLOAD', { sidebarId, payload });
    } else {
      commit('REMOVE_SIDEBAR', sidebarId);
      dispatch('broadcastSidebarRemoval', sidebar);
    }
  },
  removeSidebarsByTag ({ state, dispatch }, sidebarTag) {
    if (!sidebarTag) {
      console.error('Sidebar tag required to remove a sidebar');
      return;
    }

    const sidebars = state.activeSidebars.filter(s => s.tag === sidebarTag);
    sidebars.forEach(sidebar => dispatch('removeSidebar', sidebar.id));
  },
  maximizeSidebar ({ state, commit }, sidebarId) {
    const hasSidebarId = state.activeSidebars.some(s => s.id === sidebarId);
    commit('SET_MAXIMIZED_ID', hasSidebarId ? sidebarId : null);
  },
  minimizeSidebar ({ commit }) {
    commit('SET_MAXIMIZED_ID', null);
  },
  initBroadcastChannel ({commit, dispatch}) {
    const channel = new BroadcastChannel('care_portal_sidebar_communication');
    channel.onmessage = (event) => {
      dispatch('handleBroadcastChannelEvent', event);
    };
    commit('SET_BROADCAST_CHANNEL', channel);
  },
  broadcastSidebarRemoval ({state}, sidebar) {
    state.broadcastChannel?.postMessage({
      type: SIDEBAR_BROADCAST_EVENT_TYPES.REMOVE_SIDEBAR, 
      payload: sidebar
    })
  },
  handleBroadcastChannelEvent({state, dispatch}, event) {
    if (!event) {
      console.error('Event must be passed into broadcast channel handler');
      return;
    }
    const {type, payload} = event.data;
    if (type === SIDEBAR_BROADCAST_EVENT_TYPES.REMOVE_SIDEBAR && payload?.tag === SIDEBAR_TAGS.COMMUNICATION_LOG) {
      const removedCallId = payload?.payload?.callId;
      const matchingSidebar = state.activeSidebars.find(s => removedCallId && removedCallId === s.payload?.callId);
      if (matchingSidebar) {
        dispatch('removeSidebar', matchingSidebar.id);
      }
    }
  },
};
export const getters = {
  isSidebarOpen: (state) => (sidebarTag) => {
    return state.activeSidebars.some(sidebar => sidebar.tag === sidebarTag && !sidebar.payload?.hidden);
  },
}
