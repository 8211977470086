export function summarizeProfiles(profiles) {
  const profilesToPrint = profiles.slice(0, 5);

  let text = profilesToPrint.map(p => `${p.first_name} ${p.last_name || ''}`).join(', ');

  if (profilesToPrint.length < profiles.length) {
    text += '(and ' + (profiles.length - profilesToPrint.length) + ' more)';
  }

  return text;
}

/**
 * The codes are like 'ethnicity_south_asian' so we want that to be 'South Asian'
 *
 * @param ethnicity_code
 */
export function cleanEthnicity(ethnicity_code) {
  ethnicity_code = ethnicity_code.replace('ethnicity_', '');
  ethnicity_code = ethnicity_code.replace(/_/g, ' ');

  return ethnicity_code;
}

/**
 * Replace the backend module string with a displayable version.
 *
 * @param module
 */
export function cleanModule(module) {
  return {
    'aria_module_cf': 'Cystic Fibrosis',
    'aria_module_asthma': 'Asthma',
    'aria_module_default': 'Default'
  }[module];
}

export function calculateBMI(weight_in_kg, height_in_cm) {
  return (weight_in_kg / (height_in_cm * height_in_cm)) * 10000
}

export const PATIENT_JOURNEY_STATUSES = {
  ELIGIBLE: {
    name: 'Eligible',
    color: '#92969B',
  },
  NOT_AWARE: {
    name: 'Not Aware',
    color: '#B665E0',
  },
  AWARE: {
    name: 'Aware',
    color: '#FFA940',
  },
  ACTIVATED: {
    name: 'Activated',
    color: '#389E0D',
  },
  MANAGED: {
    name: 'Managed',
    color: '#0073E6',
  },
  DECLINED_PROSPECT: {
    name: 'Declined Prospect',
    color: '#FFA940',
  },
  DISENROLLED: {
    name: 'Disenrolled',
    color: '#e60000',
  },
}

export const PROFILE_PRACTITIONER_TYPES = {
  REFERRAL: 'Referral',
  PRIMARY: 'Primary',
  OTHER: 'Other',
  REFERRAL_AND_PRIMARY: 'ReferralAndPrimary',
}