// appointmentsStore.js
import { ref, computed, getCurrentInstance } from "@vue/composition-api";
import moment from "moment";


export function useAppointments () {
    const instance = getCurrentInstance();
    const apiv2 = instance?.proxy.$apiv2;

    const loading = ref(false);
    const loadingCareTeam = ref(false);
    const lastUpdatedDate = ref(null);
    const appointments = ref([]);
    const totalRecords = ref(0);
    const careTeamBookingLinks = ref([]);
    const fixingAppointment = ref(false);
    const fuzzyMatches = ref([]);

    const SET_LOADING = (newVal) => {
        loading.value = newVal;
    }
    const SET_LOADING_CARE_TEAM = (newVal) => {
        loadingCareTeam.value = newVal;
    }
    const SET_CARE_TEAM_BOOKING_LINKS = (newVal) => {
        careTeamBookingLinks.value = newVal;
    }
    const SET_TOTAL_RECORDS = (newVal) => {
        totalRecords.value = newVal;
    }
    const SET_APPOINTMENTS = (newVal) => {
        appointments.value = newVal;
        lastUpdatedDate.value = moment();
    }
    const ADD_APPOINTMENT = (appointment) => {
        appointments.value.push(appointment);
    }
    const UPDATE_APPOINTMENT = (updatedAppointment) => {
        const appointmentIndex = appointments.value.findIndex(appointment => appointment.id === updatedAppointment.id);
        if (appointmentIndex !== -1) {
            appointments.value.splice(appointmentIndex, 1, updatedAppointment);
        }
    }
    const ADD_APPOINTMENT_NOTE = (newNote) => {
        const appointmentIndex = appointments.value.findIndex(appointment => appointment.id === newNote.appointment.id);
        if (appointmentIndex !== -1) {
            appointments.value[appointmentIndex].notes.push(newNote);
        }
    }
    const UPDATE_APPOINTMENT_NOTE = (updatedNote) => {
        const appointmentIndex = appointments.value.findIndex(appointment => appointment.id === updatedNote.appointment.id);
        if (appointmentIndex !== -1) {
            const noteIndex = appointments.value[appointmentIndex].notes.findIndex(note => note.id === updatedNote.id);
            if (noteIndex !== -1) {
                appointments.value[appointmentIndex].notes.splice(noteIndex, 1, updatedNote);
            }
        }
    }
    const DELETE_APPOINTMENT_NOTE = (noteId) => {
        const appointmentIndex = appointments.value.findIndex(appointment => appointment.notes.some(note => note.id === noteId));
        if (appointmentIndex !== -1) {
            appointments.value[appointmentIndex].notes = appointments.value[appointmentIndex].notes.filter(note => note.id !== noteId);
        }
    }

    const removeAppointmentFromTable = (appointmentId) => {
        const appointmentIndex = appointments.value.findIndex(appointment => appointment.id === appointmentId);
        if (appointmentIndex !== -1) {
            appointments.value.splice(appointmentIndex, 1);
        }
    }


    const fetchAppointments = async (params) => {
        SET_LOADING(true);
        const response = await apiv2.getAppointments(params);
        SET_APPOINTMENTS(response.data);
        SET_TOTAL_RECORDS(parseInt(response.headers['x-total-count']));
        SET_LOADING(false);
        return response.data;
    };
    const createAppointmentToAdverseEvent = async ({ adverseEventId, appointmentData }) => {
        const newAppointment = await apiv2.addAppointmentToAdverseEvent(adverseEventId, appointmentData);
        ADD_APPOINTMENT(newAppointment);
        return newAppointment;
    };
    const createAppointment = async ({ profileId, appointmentData }) => {
        const newAppointment = await apiv2.addAppointment(profileId, appointmentData);
        ADD_APPOINTMENT(newAppointment);
        return newAppointment;
    };
    const updateAppointment = async ({ appointmentId, appointmentData }) => {
        const appointmentPayload = { ...appointmentData, id: appointmentId };
        const updatedAppointment = await apiv2.updateAppointment(appointmentId, appointmentPayload);
        UPDATE_APPOINTMENT(updatedAppointment);
        return updatedAppointment;
    };
    const addNoteToAppointment = async ({ appointmentId, notePayload }) => {
        const newNote = await apiv2.addAppointmentNote(appointmentId, notePayload);
        ADD_APPOINTMENT_NOTE(newNote);
        return newNote;
    };
    const updateNoteOnAppointment = async ({ noteId, notePayload }) => {
        const updatedNote = await apiv2.updateAppointmentNote(noteId, notePayload);
        UPDATE_APPOINTMENT_NOTE(updatedNote);
        return updatedNote;
    };
    const deleteNoteOnAppointment = async ({ noteId }) => {
        await apiv2.deleteAppointmentNote(noteId);
        DELETE_APPOINTMENT_NOTE(noteId);
    };
    const fetchCareTeamBookingLinks = async (params) => {
        SET_LOADING_CARE_TEAM(true);
        const response = await apiv2.getAppointmentCareTeamBookingLinks(params);
        SET_CARE_TEAM_BOOKING_LINKS(response);
        SET_LOADING_CARE_TEAM(false);
        return response;
    };

    const userMappedBookingLinks = computed(() => careTeamBookingLinks.value.reduce((acc, link) => {
        const user = acc.find(u => u.userId === link.userId) || { userId: link.userId, userIdPii: link.userIdPii, email: link.email, bookingLinks: [] };
        user.bookingLinks.push(link);
        if (!acc.some(u => u.userId === link.userId)) acc.push(user);
        return acc;
    }, []));

    const appointmentById = (id) => appointments.value.find(appointment => appointment.id === id);
    const lastUpdatedDateFromNow = computed(() => lastUpdatedDate.value ? lastUpdatedDate.value.fromNow() : null);
    const getNextAppointmentByPrincipalId = computed((principalId) => appointments.value
        .filter(app => app.principal.id === principalId)
        .sort((a, b) => moment(a.start).diff(moment(b.start)))
        .find(app => moment(app.start).isAfter(moment()))
    );

    const fixAppointment = async (appointmentId) => {
        try {
            fixingAppointment.value = true;
            const response = await apiv2.fixAppointment(appointmentId);
            if (response.success) {
                await fetchAppointments(lastFetchParams.value);
                fuzzyMatches.value = [];
                return { success: true };
            } else {
                fuzzyMatches.value = response.fuzzyMatches || [];
                return { success: false, fuzzyMatches: response.fuzzyMatches, message: response.message };
            }
        } catch (error) {
            console.error('Error fixing appointment:', error);
            throw error;
        } finally {
            fixingAppointment.value = false;
        }
    };

    const reassignAppointmentPatient = async (appointmentId, profileId) => {
        try {
            fixingAppointment.value = true;
            await apiv2.reassignAppointmentPatient(appointmentId, profileId);
            await fetchAppointments(lastFetchParams.value);
            fuzzyMatches.value = [];
            return { success: true };
        } catch (error) {
            console.error('Error reassigning appointment patient:', error);
            throw error;
        } finally {
            fixingAppointment.value = false;
        }
    };

    return {
        loading,
        fixingAppointment,
        fuzzyMatches,
        lastUpdatedDate,
        appointments,
        totalRecords,
        fetchAppointments,
        createAppointmentToAdverseEvent,
        createAppointment,
        updateAppointment,
        removeAppointmentFromTable,
        addNoteToAppointment,
        updateNoteOnAppointment,
        deleteNoteOnAppointment,
        appointmentById,
        lastUpdatedDateFromNow,
        getNextAppointmentByPrincipalId,
        fetchCareTeamBookingLinks,
        careTeamBookingLinks,
        userMappedBookingLinks,
        fixAppointment,
        reassignAppointmentPatient
    };
}

