<template>
  <div class="flex flex-column flex-grow-0">
    <Message v-if="errorMessage" severity="error">{{ errorMessage }}</Message>
    <Textarea v-model="compiledText" :disabled="true" rows="5" cols="30" />
  </div>
</template>

<script>
import PatientSearch from "@/components/common/PatientSearch";
import SidebarContent from "@/components/layout/sidebar/SidebarContent";
import { ref, computed, onMounted, onBeforeUnmount, watch } from '@vue/composition-api';

export default {
  components: {
    PatientSearch,
    SidebarContent,
  },
  props: {
    recording: {
      type: Boolean,
      default: false,
    }
  },
  setup (props, { emit }) {
    const speechText = ref('');
    const temporaryText = ref('');
    const errorMessage = ref('');
    const speechRecognition = ref(null);
    const recording = computed(() => props.recording);
    const retryCount = ref(0);
    const retryInterval = ref(null);

    const compiledText = computed(() => speechText.value + temporaryText.value);

    const setupSpeechRecognition = () => {
      speechRecognition.value = new window.webkitSpeechRecognition();
      speechRecognition.value.continuous = true;
      speechRecognition.value.interimResults = true;
      speechRecognition.value.language = 'en-US';
      speechRecognition.value.onstart = recognition_startHandler;
      speechRecognition.value.onend = recognition_endHandler;
      speechRecognition.value.onresult = recognition_resultHandler;
      speechRecognition.value.onerror = recognition_errorhandler;
    }

    const tearDownSpeechRecognition = () => {
      speechRecognition.value = null;
      tearDownRetryDetection();
    }

    const setupRetryDetection = () => {
      retryInterval.value = setInterval(() => {
        if (retryCount.value) {
          retryCount.value = 0;
        }
      }, 10000)
    }

    const tearDownRetryDetection = () => {
      retryInterval.value && clearInterval(retryInterval.value);
      retryInterval.value = null;
    }

    const recognition_startHandler = () => {
      console.log('Speech recognition initiated');
    };

    const recognition_endHandler = () => {
      // If speech recognition ended due to error, retain previous text and retry.
      if (!recording.value) {
        speechText.value = '';
      } else if (retryCount.value > 3) {
        errorMessage.value = errorMessage.value || 'Unknown Error';
      } else {
        speechRecognition.value.start();
        retryCount.value += 1;
      }
    };

    const recognition_resultHandler = (e) => {
      let interimText = '';
      for (let i = e.resultIndex; i < e.results.length; i++) {
        if (e.results[i].isFinal) {
          speechText.value = speechText.value + e.results[i][0].transcript;
          interimText = '';
        } else {
          interimText = interimText + e.results[i][0].transcript;
        }
      }
      if (interimText) {
        errorMessage.value = '';
      }
      temporaryText.value = interimText;
    }

    const recognition_errorhandler = (e) => {
      if (e.error == 'no-speech') {
        errorMessage.value = 'Speech not detected.';
      } else if (e.error == 'audio-capture') {
        errorMessage.value = 'No audio device detected.';
      } else if (e.error == 'not-allowed') {
        errorMessage.value = 'Browser blocked audio recording';
      } else {
        console.error('Speech Recognition Error: ', e.error);
      }
    }

    onMounted(() => {
      setupSpeechRecognition();
      if (recording.value) {
        speechRecognition.value.start();
      }
    });

    onBeforeUnmount(() => {
      tearDownSpeechRecognition();
    });

    watch(() => recording.value, (isRecording) => {
      if (!speechRecognition.value) {
        console.error('Speech recognition not set up yet.');
        return;
      }
      if (isRecording) {
        setupRetryDetection();
        speechRecognition.value.start()
      } else {
        speechRecognition.value.stop()
        tearDownRetryDetection();
      }
    })

    watch(() => speechText.value, (text) => {
      if (text) {
        emit('update', text);
      }
    })

    return {
      errorMessage,
      compiledText,
    }
  },
};
</script>

<style lang="scss" scoped>

</style>