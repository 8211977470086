export const SIDEBAR_TAGS = {
  ZOOM: 'zoom',
  COMMUNICATION_LOG: 'communication-log',
  ZOOM_TRANSCRIPT: 'zoom-transcript',
  CHAT: 'chat',
  CALENDAR: 'calendar',
  TIME_TRACKER: 'time-tracker'
}

export const SIDEBAR_COMPONENTS = {
  [SIDEBAR_TAGS.ZOOM]: 'ZoomSidebar',
  [SIDEBAR_TAGS.COMMUNICATION_LOG]: 'CommunicationLogSidebar',
  [SIDEBAR_TAGS.ZOOM_TRANSCRIPT]: 'ZoomTranscriptSidebar',
  [SIDEBAR_TAGS.CHAT]: 'ChatSidebar',
  [SIDEBAR_TAGS.CALENDAR]: 'CalendarSidebar',
  [SIDEBAR_TAGS.TIME_TRACKER]: 'TimeTrackerSidebar',
}

export const PERSISTENT_SIDEBARS = [SIDEBAR_TAGS.ZOOM, SIDEBAR_TAGS.TIME_TRACKER];

export const SIDEBAR_BROADCAST_EVENT_TYPES = {
  REMOVE_SIDEBAR: 'remove-sidebar'
}
