export const purposeList = [
    { label: 'Service Awareness', key: 'SERVICE_AWARENESS' },
    { label: 'Onboarding', key: 'ONBOARDING' },
    { label: 'Care Event', key: 'CARE_EVENT' },
    { label: 'Health Coaching', key: 'HEALTH_COACHING' },
    { label: 'Respiratory Therapy', key: 'RESPIRATORY_THERAPY' },
    { label: 'Remote Patient Monitoring', key: 'REMOTE_PATIENT_MONITORING' },
    { label: 'Digital Support', key: 'DIGITAL_SUPPORT' },
    { label: 'Self Report', key: 'SELF_REPORT' }, // support showing on create
    { label: 'Appointment', key: 'APPOINTMENT' },
    { label: 'Offboarding', key: 'OFF_BOARDING' },
    { label: 'Consumables', key: 'CONSUMABLES' },
    { label: 'Testimonial', key: 'TESTIMONIAL' },
    { label: 'Engage & Transfer', key: 'ENGAGE_TRANSFER'},
    { 
        label: 'EHR Review/Updates', 
        key: 'EHR_REVIEW_UPDATE', 
        hasNoPatientInteraction: true,
        defaultValues: {
            duration: {value: 20}, 
            medium: {value: 'NONE', overwriteCurrentValue: true},
            direction: {value: 'NONE', overwriteCurrentValue: true},
            communicationResult: {value: 'PATIENT_UPDATED', overwriteCurrentValue: true}, 
            subject: {value: 'Patient Information Updates'},
        },
        disabledFields: ['medium','direction','communicationResult'],
    },
    { 
        label: 'Care Plan Review/Updates', 
        key: 'CARE_PLAN_REVIEW_UPDATE', 
        hasNoPatientInteraction: true,
        defaultValues: {
            duration: {value: 20}, 
            medium: {value: 'NONE', overwriteCurrentValue: true},
            direction: {value: 'NONE', overwriteCurrentValue: true},
            communicationResult: {value: 'PATIENT_UPDATED', overwriteCurrentValue: true}, 
            subject: {value: 'Patient Information Updates'},
        },
        disabledFields: ['medium', 'direction', 'communicationResult'],
     },
     {
        label: 'MA Activity',
        key: 'MA_ACTIVITY',
        hasNoPatientInteraction: false,
        defaultValues: {
            direction: {value: 'NONE', overwriteCurrentValue: true},
            medium: {value: 'NONE', overwriteCurrentValue: true},
            purpose: "MA_ACTIVITY",
        },
        disabledFields: ['medium', 'direction', 'communicationResult']
     },
]


export const channelList = [
    { label: 'Phone', key: 'VERBAL_PHONE' },
    { label: 'Video Conference', key: 'VERBAL_VIDEO_CONFERENCE' },
    { label: 'SMS', key: 'WRITTEN_SMS' },
    { label: 'SMS', key: 'AUTOMATED_SMS', hidden: true },
    { label: 'In Person', key: 'VERBAL_IN_PERSON'},
    { label: 'N/A', key: 'NONE' },
    // "VERBAL_PHONE",
    // "VERBAL_VIDEO_CONFERENCE",
    // "WRITTEN_SMS",
    // "VERBAL_AUTOMATED",
    // "VERBAL_IN_PERSON",
    // "WRITTEN_HAND",
    // "WRITTEN_MAIL",
    // "WRITTEN_EMAIL",
    // "WRITTEN_PUSH_NOTIFICATION",
    // "WRITTEN_MMS",
    // "WRITTEN_MESSAGING_APP"
]

export const directionList = [
    { label: 'Outbound', key: 'OUTBOUND' },
    { label: 'Inbound', key: 'INBOUND' },
    { label: 'None', key: 'NONE', hidden: true },
]

export const resultList = {
    "VERBAL_PHONE": [
        { label: 'Answered', key: 'ANSWERED' },
        { label: 'Message Machine', key: 'MESSAGE_MACHINE' },
        { label: 'Busy', key: 'BUSY' },
        { label: 'Rejected', key: 'REJECTED' },
        { label: 'No Answer', key: 'NO_ANSWER' }
    ],
    "VERBAL_VIDEO_CONFERENCE": [
        { label: 'Answered', key: 'ANSWERED' },
        { label: 'Message Machine', key: 'MESSAGE_MACHINE' },
        { label: 'Busy', key: 'BUSY' },
        { label: 'No Answer', key: 'NO_ANSWER' }
    ],
    "WRITTEN_SMS": [
        { label: 'Delivered', key: 'DELIVERED' },
        { label: 'Error', key: 'ERROR' },
        { label: 'Unknown', key: 'UNKNOWN' },
    ],
    "VERBAL_IN_PERSON": [
        { label: 'Appointment Complete', key: 'APPOINTMENT_COMPLETE' },
        { label: 'Appointment Rescheduled', key: 'APPOINTMENT_RESCHEDULED' },
        { label: 'Appointment Cancelled With No Reschedule', key: 'APPOINTMENT_CANCELLED_WITH_NO_RESCHEDULE' },
        { label: 'In Progress', key: 'IN_PROGRESS'},
    ],
    "NONE": [
        {label: 'Patient Updated', key: 'PATIENT_UPDATED'},
        {label: 'In Progress', key: 'IN_PROGRESS'},
    ]
}

export const systemNoteSubjects = [
    'Patient Phone', 
    'PatientPhoneNumber',
    'SMS Note', 
    'Patient Notification Detail', 
    'Zoom Import Note', 
    'Zoom SMS Sent Import Note', 
    'Zoom SMS Received Import Note', 
    'Zoom Recording Note', 
    'Zoom Recording Transcript Note',
    'ExecutionId',
    'UpdateCommunicationLog | HttpUpdateCommunicationLogTransfer',
    'UpdateCommunicationLog | HttpUpdateCommunicationLog',
    'UpdateCommunicationLog | SmsHttpUpdateCommunicationLog',
    'CallBackUpdateComLogPayload',
    'FlowId',
];