<template>
  <SidebarContent :id="id" :payload="payload">
    <template v-slot:sidebar-header>
      <div class="zoom-header font-medium text-2xl">
        Zoom Phone
      </div>
    </template>
    <template v-slot:sidebar-content> 
      <div v-if="callsInProgress.length" class="flex flex-column mt-3 pa-3 bg-gray-300">
          <span class="mb-1">You are in the following zoom call(s):</span>
          <div v-for="call in callsInProgress" 
            class="flex flex-row align-items-center justify-space-between py-0 px-3 border-round-md bg-white border-bottom-1 border-gray-300">
            <span class="my-3">{{callParticipantName(call) || 'Loading...'}}</span>
            <Button v-if="call.logId" label="View Log" icon="pi pi-directions-alt" class="my-2 mx-3 align-self-center p-button-sm" @click="viewLog_clickHandler(call)" />
          </div>
        </div>
        <div class="flex flex-column align-items-center h-full">
          <h3 class="text-white mt-6 pa-2 border-round-top-3xl text-center w-full bg-primary">Zoom Embedded Phone</h3>
          <iframe 
            src="https://applications.zoom.us/integration/phone/embeddablephone/home" 
            id="zoom-embeddable-phone-iframe" 
            allow="clipboard-read; clipboard-write https://applications.zoom.us"
            ref="zoomPhoneEmbeddedIFrame_Sidebar"
          ></iframe>
        </div>
    </template>
    <template v-slot:sidebar-footer>
    </template>
  </SidebarContent>
</template>

<script>
import SidebarContent from "@/components/layout/sidebar/SidebarContent";
import { SIDEBAR_TAGS } from "@/components/layout/sidebar/SidebarConstants";
import {createNamespacedHelpers} from 'vuex';

const zoomVuex = createNamespacedHelpers('zoom');
const sidebarVuex = createNamespacedHelpers('sidebar');
const communicationLogsVuex = createNamespacedHelpers('communicationLogs');

export default {
  components: {
    SidebarContent,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    payload: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      interval: null,
      lastUpdateKey: 0,
    }
  },
  mounted() {
    window.addEventListener('message', this.handleMessageEvent);
    this.initializeZoomPhone();
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleMessageEvent);
    this.tearDownAutoRefresh();
  },
  computed: {
    ...zoomVuex.mapGetters(['callParticipantName', 'callParticipantPhoneNumber']),
    ...zoomVuex.mapState(['callsInProgress']),
  },
  methods: {
    ...zoomVuex.mapActions(['addCallInProgress', 'setCallTarget', 'removeCallInProgress', 
        'initializeZoomPhone', 'getLogIdsForCallsInProgress',
        'resolveCallAttempt']),
    ...sidebarVuex.mapActions(['expandSidebar', 'addSidebar']),
    ...communicationLogsVuex.mapActions(['fetchLogByCallId']),
    initializeLogLoading(callData) {
      this.addCallInProgress(callData);
      this.addSidebar({
        tag: SIDEBAR_TAGS.COMMUNICATION_LOG, 
        payload: {
          logId: null, 
          callId: callData.callId,
          site: null,
        }
      });
      this.expandSidebar();
    },
    handleMessageEvent(e) {
      const data = e.data;
      if (data) {
        switch (data.type) {  // cases are from Zoom Phone Smart Embed api
          case 'zp-call-ringing-event':
            this.resolveCallAttempt();
            if (data.data?.direction == 'outbound') {
              this.initializeLogLoading(data.data);
            }
            break;
          case 'zp-call-connected-event':
            if (data.data?.direction == 'inbound') {
              this.initializeLogLoading(data.data);
            }
            break;
          case 'zp-call-ended-event':
            setTimeout(() => {
              this.callEnd_handler(data.data.callId);
            }, 2000);
            break;
        }
      }
    },
    async callEnd_handler(callId) {
      await this.getLogIdsForCallsInProgress(); // Allows us to still get comm log ids for calls that end before or immediately after connecting.
      this.fetchLogByCallId(callId);
      this.removeCallInProgress(callId);
    },
    async viewLog_clickHandler(call) {
      if (call.logId) {
        this.addSidebar({
            tag: SIDEBAR_TAGS.COMMUNICATION_LOG, 
            payload: {
              logId: call.logId, 
              site: call.target?.site,
            }
          });
      }
    },
    setupAutoRefresh () {
      if (!this.interval) {
        this.interval = setInterval(() => {
          this.getLogIdsForCallsInProgress();
          this.lastUpdateKey += 1
        }, 3000);
      }
    },
    tearDownAutoRefresh () {
      clearInterval(this.interval);
      this.interval = null;
    },
  },
  watch: {
    callsInProgress: {
      handler: async function (val) {
        if (val.length) {
          if (val.some(callInProgress => !callInProgress.logId)) {
            this.setupAutoRefresh();
          } else {
            this.tearDownAutoRefresh();
          };
        } else if (this.interval) {
          this.tearDownAutoRefresh();
        }
      },
      deep: true
    },
  }
};
</script>

<style lang="scss" scoped>
.sidebar-main {
  padding: 20px 24px 24px;
}

.maximized {
  .zoom-header {
    padding-top: 24px;
  }
}

#zoom-embeddable-phone-iframe {
  width: 100%;
  height: 100%;
}
</style>
